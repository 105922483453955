import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

export interface IFooterSocialLink {
  name: string;
  link: string;
  icon: IconDefinition;
}

export const FooterSocialLinks: IFooterSocialLink[] = [
  {
    name: 'Facebook',
    link: 'https://www.facebook.com/www.smilestoyou.ca/',
    icon: faFacebookF
  },
  {
    name: 'Instagram',
    link: 'mailto:contact@smilestoyou.ca',
    icon: faEnvelope
  }
];
