<form (ngSubmit)="submit()" [formGroup]="contactForm">
  <section class="styContactForm-errors" *ngIf="errors?.length" [@messageAnimation]>
    <section class="styContactForm-error" *ngFor="let error of errors | async">{{ error }}</section>
  </section>

  <section class="styContactForm-success" *ngIf="submitted" [@messageAnimation]>
    Thanks for your message! We'll get back to you as soon as possible!
  </section>

  <section class="styContactForm-fields">
    <sty-form-input [control]="contactForm.get('name')" for="name" label="Your Name">
      <input class="styContactForm-nameInput" id="name" type="text" autocomplete="name" placeholder="Your Name" formControlName="name" />
    </sty-form-input>
    <sty-form-input [control]="contactForm.get('email')" [errorMessages]="{email: 'Please enter a valid email!'}" for="email" label="Email Address">
      <input class="styContactForm-emailInput" id="email" type="email" autocomplete="email" placeholder="Email Address" formControlName="email" />
    </sty-form-input>
    <sty-form-input [control]="contactForm.get('phone')" [errorMessages]="{pattern: 'Please enter a valid phone number! (000) 000-0000'}" for="phone" label="Phone Number">
      <input class="styContactForm-phoneInput" id="phone" type="tel" autocomplete="tel" placeholder="Phone Number" formControlName="phone" mask="(000) 000-0000" />
    </sty-form-input>
    <sty-form-input *ngIf="showMobileServiceLocation" [control]="contactForm.get('mobileServicesLocation')" for="mobileServicesLocation" label="Type of Location">
      <select id="mobileServicesLocation" name="mobileServicesLocation" class="styContactForm-mobileLocationSelect" formControlName="mobileServicesLocation">
        <option [ngValue]="null" disabled>Type of Location</option>
        <option [ngValue]="mobileServiceLocationTypes.Retirement">{{ mobileServiceLocationTypes.Retirement }}</option>
        <option [ngValue]="mobileServiceLocationTypes.Longterm">{{ mobileServiceLocationTypes.Longterm }}</option>
        <option [ngValue]="mobileServiceLocationTypes.Home">{{ mobileServiceLocationTypes.Home }}</option>
        <option [ngValue]="mobileServiceLocationTypes.Business">{{ mobileServiceLocationTypes.Business }}</option>
      </select>
    </sty-form-input>
    <sty-form-input [control]="contactForm.get('message')" [errorMessages]="{minlength: 'Please enter a longer message!'}" for="message" label="Your Message">
      <textarea id="message" name="message" class="styContactForm-messageInput" rows="3" placeholder="Your Message" formControlName="message"></textarea>
    </sty-form-input>
  </section>

  <section class="styContactForm-subscription" *ngFor="let sub of availableMailchimpLists">
    <button type="button" class="styContactForm-subscriptionToggle" [class.isToggled]="isToggled(sub)" (click)="toggleSub(sub)"><fa-icon [icon]="faCheck"></fa-icon> <i class="fas fa-check"></i></button>
    <section class="styContactForm-subscriptionLabel" (click)="toggleSub(sub)">{{ labelForSubList(sub) }}</section>
  </section>

  <button type="submit" class="styContactForm-submitBtn" [disabled]="submitting">
    <section class="styContactForm-submitBtnText" *ngIf="!submitting" [@btnTextEnabledAnimation]>{{ submitBtnText }}</section>
    <section class="styContactForm-submitBtnLoading" *ngIf="submitting"[@btnTextDisabledAnimation]>Submitting...</section>
  </button>
</form>
