import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { MessageContext, IMessage, MailchimpTypes, SubscribeMessages, MobileServiceLocation } from '../../message/message';
import { ReactiveComponent } from '../../reactive/reactive.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'src/app/message/message.service';
import { MaskPipe } from 'ngx-mask';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  animations: [
    trigger('messageAnimation', [
      transition(':enter', [
        style({ opacity: 1, height: 0, overflow: 'hidden' }),
        animate('200ms ease', style({ opacity: 1, height: '*' }))
      ]),
      transition(':leave', [
        style({ opacity: 0, height: '*', overflow: 'hidden' }),
        animate('200ms ease', style({ opacity: 0, height: 0 }))
      ])
    ]),
    trigger('btnTextEnabledAnimation', [
      transition(':enter', [
        style({ position: 'absolute', transform: 'translateY(-150%) translateX(-50%)', top: '50%', left: '50%' }),
        animate('150ms linear', style({ position: 'absolute', transform: 'translateY(-50%) translateX(-50%)', top: '50%', left: '50%' }))
      ]),
      transition(':leave', [
        style({ position: 'absolute', transform: 'translateY(-50%) translateX(-50%)', top: '50%', left: '50%' }),
        animate('150ms linear', style({ position: 'absolute', transform: 'translateY(-150%) translateX(-50%)', top: '50%', left: '50%' }))
      ])
    ]),
    trigger('btnTextDisabledAnimation', [
      transition(':enter', [
        style({ position: 'absolute', transform: 'translateY(50%) translateX(-50%)', top: '50%', left: '50%' }),
        animate('150ms linear', style({ position: 'absolute', transform: 'translateY(-50%) translateX(-50%)', top: '50%', left: '50%' }))
      ]),
      transition(':leave', [
        style({ position: 'absolute', transform: 'translateY(-50%) translateX(-50%)', top: '50%', left: '50%' }),
        animate('150ms linear', style({ position: 'absolute', transform: 'translateY(50%) translateX(-50%)', top: '50%', left: '50%' }))
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sty-contact-form',
  styleUrls: ['./contact-form.component.scss'],
  templateUrl: './contact-form.component.html',
  providers: [
    MaskPipe
  ]
})
export class ContactFormComponent extends ReactiveComponent implements OnInit {
  @Input() submitBtnText = 'Request Appointment';
  @Input() availableMailchimpLists: MailchimpTypes[] = [MailchimpTypes.Generic];
  @Input() showMobileServiceLocation: boolean = false;
  @Input() context!: MessageContext;
  @Input() clinicSlug?: string;

  @Output() sendSuccess: EventEmitter<void> = new EventEmitter<void>();

  contactForm: FormGroup;
  faCheck: IconDefinition = faCheck;
  
  submitted: boolean = false;
  submitting: boolean = false;
  errors: string[] | null = null;

  mobileServiceLocationTypes: typeof MobileServiceLocation = MobileServiceLocation;

  constructor(private _fb: FormBuilder, private _messageService: MessageService, private _maskPipe: MaskPipe) {
    super();
  }

  ngOnInit(): void {
    this._resetForm();
  }

  submit(): void {
    this.contactForm.markAllAsTouched();
    this.contactForm.updateValueAndValidity();

    if (this.contactForm.invalid || this.submitting) {
      return;
    }

    this.submitting = true;
    this.errors = null;
    this.submitted = false;

    const { name, email, phone, message: text, mailchimpLists, mobileServicesLocation } = this.contactForm.value;

    const message: Partial<IMessage> = {
      name,
      email,
      phone: this._maskPipe.transform(phone, '(000) 000-0000'),
      message: text,
      mailchimp_lists: mailchimpLists,
      context: this.context || MessageContext.Navbar
    }

    if (this.clinicSlug) message.clinic_slug = this.clinicSlug;
    if (this.showMobileServiceLocation) message.mobile_services_location = mobileServicesLocation;

    this._messageService.create(message).subscribe(
      () => {
        this.submitted = true;
        this._resetForm();
      },
      (errors) => {
        this.submitting = false;
        this.errors = errors || ['Please fill out the form!'];
      }
    )
  }

  labelForSubList(listType: MailchimpTypes): string {
    return SubscribeMessages[listType];
  }

  toggleSub(type: MailchimpTypes): void {
    const mailchimpLists: MailchimpTypes[] = this.contactForm.get('mailchimpLists').value;

    if (this.isToggled(type)) {
      this.contactForm.patchValue({mailchimpLists: mailchimpLists.filter((t) => t !== type)});
    } else {
      this.contactForm.patchValue({mailchimpLists: [...mailchimpLists, type]});
    }
  }

  isToggled(type: MailchimpTypes): boolean {
    const mailchimpLists: MailchimpTypes[] = this.contactForm.get('mailchimpLists').value;

    return mailchimpLists.indexOf(type) >= 0;
  }

  private _resetForm(): void {
    if (this.contactForm) {
      this.contactForm.patchValue({
        name: '',
        email: '',
        phone: '',
        message: '',
        mailchimpLists: []
      })

      if (this.showMobileServiceLocation) {
        this.contactForm.patchValue({mobileServicesLocation: null});
      }

      this.contactForm.markAsPristine();
      this.contactForm.markAsUntouched();

      this.errors = null;
      this.submitting = false;

      return;
    }

    this.contactForm = this._fb.group({
      name: this._fb.control('', [Validators.required]),
      email: this._fb.control('', [Validators.required, Validators.email]),
      phone: this._fb.control('', [Validators.required, Validators.pattern(new RegExp(/^[0-9]{10}/))]),
      message: this._fb.control('', [Validators.required, Validators.minLength(10)]),
      mailchimpLists: this._fb.control([])
    });

    if (this.showMobileServiceLocation) {
      this.contactForm.addControl('mobileServicesLocation', this._fb.control(null, [Validators.required]));
    }

    this.errors = null;
    this.submitting = false;
  }
}
