export interface IMeta {
  title?: string;
  description?: string;
  openGraph?: IOpenGraph;
}

export interface IOpenGraph {
  url?: string;
  type?: OpenGraphType;
  title?: string;
  description?: string;
  image?: string;
}

export enum OpenGraphType {
  BusinessBusiness = 'business.business',
  Profile = 'profile'
}
