import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from '../environments/environment';
import { MapService } from './map/map.service';
import { Observable } from 'rxjs';
import { MapKitInitOptions, MapConstructorOptions } from 'ngx-apple-maps/lib/declarations';
import { isPlatformBrowser } from '@angular/common';
import { generateGlyph } from './map/map.helpers';
import { IMapAnnotation } from './map/map';

@Component({
  selector: 'sty-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  officeLat = environment.officeLocation.lat;
  officeLng = environment.officeLocation.lng;

  mapkitReady$: Observable<boolean>;

  mapOptions: MapKitInitOptions;
  mapSettings: MapConstructorOptions;


  mapAnnotation: IMapAnnotation = {
    latitude: this.officeLat,
    longitude: this.officeLng,
    annotationOptions: {
      color: '#ffffff',
      glyphColor: '#2fc1e1',
      glyphImage: generateGlyph('map-icon'),
      selectedGlyphImage: generateGlyph('map-icon-selected'),
      selected: true
    }
  };

  constructor(
    private _mapService: MapService,
    @Inject(PLATFORM_ID) platformId
  ) {
    if (!isPlatformBrowser(platformId)) {
      return;
    }

    this.mapSettings = {
      region: {
        center: {
          latitude: this.officeLat,
          longitude: this.officeLng,
        },
        span: {
          from: .01,
          to: .01
        }
      },
      colorScheme: 'light',
      tintColor: '#2fc1e1',
      mapType: 'standard',
      showsMapTypeControl: false,
      showsCompass: 'hidden'
    };
  }
}
