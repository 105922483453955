<section class="styHomepage-landingImageContainer"></section>

<section class="styHomepage-missionStatement">
  <img alt="Smiles to You Logo" loading="lazy" class="styHomepage-missionStatementLogo" styStatic="logos/blue-on-white/icon-only.png">
  <header class="styHomepage-missionStatementText">
    Let us help you Love your Smile!
  </header>
</section>

<section class="styHomepage-profiles">
  <section class="styHomepage-profilesText">
    <section class="styHomepage-profilesTextTop">
      Benefit from the complimentary experience and skillset of our caring and friendly oral maintenance team.
    </section>
    <section class="styHomepage-profilesTextBtm">
      Welcome to professional, affordable, and compassionate Denture and Dental Hygiene Care.
    </section>
  </section>

  <section class="styHomepage-profileGroup">
    <section class="styHomepage-profile">
      <section class="styHomepage-profileImg russell">
      </section>
      <section class="styHomepage-profileDetails">
        <section class="styHomepage-profileName">Russell Tate, DD</section>
        <section class="styHomepage-profilePosition">Denturist</section>
      </section>
    </section>

    <section class="styHomepage-profile">
      <section class="styHomepage-profileImg trudi">
      </section>
      <section class="styHomepage-profileDetails">
        <section class="styHomepage-profileName">Trudi Martin-Tate, RDH</section>
        <section class="styHomepage-profilePosition">Registered Dental Hygienist</section>
      </section>
    </section>
  </section>
</section>

<section class="styHomepage-contactContainer">
  <section class="styHomepage-contact">
    <section class="styHomepage-contactMessage">
      <header class="styHomepage-contactMessageHeader">
        <span class="styHomepage-contactMessageHeaderFree">Free</span> Dental Assessment
      </header>
      <section class="styHomepage-contactMessageText">
        Enjoy a no obligation assessment to determine which treatment and products are right for you
      </section>
    </section>

    <sty-contact-form class="styHomepage-contactForm" [context]="'homepage'"></sty-contact-form>
  </section>
</section>

<section class="styHomepage-services">
  <header class="styHomepage-servicesHeader">Our Services</header>

  <section class="styHomepage-service">
    <section class="styHomepage-serviceIcon">
      <img alt="Dentures" loading="lazy" class="styHomepage-serviceIconImg" styStatic="icons/services/dentures.png">
    </section>
    <section class="styHomepage-serviceContent">
      <header class="styHomepage-serviceHeader">Dentures</header>
      <section class="styHomepage-serviceText">
        Complete Denture Services<br />
        Implant Supported Dentures<br />
        Repairs/Relines
      </section>
      <a class="styHomepage-serviceLinkMobile" [routerLink]="['/services','dentures']">
        Learn More
      </a>
    </section>
    <section class="styHomepage-serviceImg denturism">
      <a class="styHomepage-serviceLink" [routerLink]="['/services','dentures']">
        Learn More
      </a>
    </section>
  </section>

  <section class="styHomepage-service">
    <section class="styHomepage-serviceIcon">
      <img alt="Dental Hygiene" loading="lazy" class="styHomepage-serviceIconImg" styStatic="icons/services/dental-hygiene.png">
    </section>
    <section class="styHomepage-serviceContent">
      <header class="styHomepage-serviceHeader">Dental Hygiene</header>
      <section class="styHomepage-serviceText">
        Teeth Cleaning<br />
        Whitening<br />
        Desensitizing<br />
        Sealants and more...
      </section>
      <a class="styHomepage-serviceLinkMobile" [routerLink]="['/services','dental-hygiene']">
        Learn More
      </a>
    </section>
    <section class="styHomepage-serviceImg dentalHygiene">
      <a class="styHomepage-serviceLink" [routerLink]="['/services','dental-hygiene']">
        Learn More
      </a>
    </section>
  </section>

  <section class="styHomepage-service">
    <section class="styHomepage-serviceIcon">
      <img alt="Laser Therapy" loading="lazy" class="styHomepage-serviceIconImg" styStatic="icons/services/laser-therapy.png">
    </section>
    <section class="styHomepage-serviceContent">
      <header class="styHomepage-serviceHeader">Laser Therapy</header>
      <section class="styHomepage-serviceText">
        Cold Sores and Canker Sores<br />
        Minor Lesions<br />
        LBR
      </section>
      <a class="styHomepage-serviceLinkMobile" [routerLink]="['/services','laser-therapy']">
        Learn More
      </a>
    </section>
    <section class="styHomepage-serviceImg laserTherapy">
      <a class="styHomepage-serviceLink" [routerLink]="['/services','laser-therapy']">
        Learn More
      </a>
    </section>
  </section>

  <section class="styHomepage-service">
    <section class="styHomepage-serviceIcon">
      <img alt="Mouthguards" loading="lazy" class="styHomepage-serviceIconImg" styStatic="icons/services/mouthguards.png">
    </section>
    <section class="styHomepage-serviceContent">
      <header class="styHomepage-serviceHeader">Mouthguards</header>
      <section class="styHomepage-serviceText">
        Custom Fit<br />
        Large Colour Selection<br />
        Quick Service
      </section>
      <a class="styHomepage-serviceLinkMobile" [routerLink]="['/services','mouthguards']">
        Learn More
      </a>
    </section>
    <section class="styHomepage-serviceImg mouthguard">
      <a class="styHomepage-serviceLink" [routerLink]="['/services','mouthguards']">
        Learn More
      </a>
    </section>
  </section>
</section>

<section class="styHomepage-insurance borderTop">
  <section class="styHomepage-insuranceContent">
    <header class="styHomepage-insuranceHeader">
      Insurance Info
      <img alt="Insurance Claim" loading="lazy" styStatic="icons/services/insurance.png" class="styHomepage-insuranceIconImgMobile">
    </header>
    <section class="styHomepage-insuranceText">
      Smiles to You can submit your insurance claim for you, most times electronically,
      for quick reimbursement. We gladly see those who are covered by Government plans as well.
    </section>
  </section>
  <section class="styHomepage-insuranceIcon">
    <img alt="Insurance Claim" loading="lazy" styStatic="icons/services/insurance.png" class="styHomepage-insuranceIconImg">
  </section>
</section>

<section class="styHomepage-mobileServices">
  <section class="styHomepage-mobileServicesContent">
    <header class="styHomepage-mobileServicesHeader">Mobile Dental Services</header>
    <section class="styHomepage-mobileServicesText">
      Convenient and affordable, we can come to you to provide ANY of our services.
      This includes private homes, businesses, retirement or Long-Term Care facilities.
      We will work with your schedule and surroundings to make it a pleasant experience.
    </section>
    <a class="styHomepage-mobileServicesLink" [routerLink]="['/services', 'mobile']">
      Learn More
    </a>
  </section>
  <section class="styHomepage-mobileServicesImg"></section>
</section>
