import { NgModule } from '@angular/core';
import { ApiModule } from '../api/api.module';
import { MessageService } from './message.service';

@NgModule({
  imports: [
    // ApiModule
  ],
  providers: [
    MessageService
  ]
})
export class MessageModule {}
