import { Component } from '@angular/core';
import { MessageContext, MailchimpTypes } from '../../message/message';
import { ActiveModal } from '../../modal/active-modal';
import { ReactiveComponent } from '../../reactive/reactive.component';
import { takeUntil } from 'rxjs/operators';
import { timer } from 'rxjs';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'sty-contact-form-modal',
  styleUrls: ['./contact-form-modal.component.scss'],
  templateUrl: './contact-form-modal.component.html'
})
export class ContactFormModalComponent extends ReactiveComponent {
  context: MessageContext;
  availableMailchimpLists?: MailchimpTypes[] = [MailchimpTypes.Generic];
  clinicSlug?: string = null;
  submitBtnText?: string = 'Request Appointment';
  showMobileServiceLocation?: boolean = false;

  faTimes = faTimes;

  constructor(private _activeModal: ActiveModal) {
    super();
  }

  closeAfterSuccess(): void {
    timer(5000).pipe(takeUntil(this._destroyed$)).subscribe(() => this.close())
  }

  close(): void {
    this._activeModal.close();
  }

  dismiss(): void {
    this._activeModal.dismiss();
  }
}
