import { OnDestroy, Directive } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Directive()
export class ReactiveComponent implements OnDestroy {
  protected _destroyed$: Subject<void> = new Subject<void>();

  private _subjects: Set<Subject<any>>;

  constructor() {
    this._subjects = new Set();
  }

  ngOnDestroy(): void {
    if (this._destroyed$ && !this._destroyed$.closed) {
      if (this._destroyed$.observers.length > 0) {
        this._destroyed$.next();
      }

      this._destroyed$.complete();
    }

    this._deregisterSubjects();
  }

  protected registerSubject<T>(): Subject<T> {
    const subject = new Subject<T>();
    this._subjects.add(subject);

    return subject;
  }

  protected registerBehaviourSubject<T>(initialValue: T = null): BehaviorSubject<T> {
    const subject = new BehaviorSubject<T>(initialValue);
    this._subjects.add(subject);

    return subject;
  }

  private _deregisterSubjects(): void {
    this._subjects.forEach((subject) => subject.complete());
  }
}
