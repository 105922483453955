import { Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { ClinicDetailComponent } from './clinic/clinic-detail/clinic-detail.component';
import { ClinicListComponent } from './clinic/clinic-list/clinic-list.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';

export const appRoutes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'contact', loadChildren: () => import('./contact/contact.module').then((m) => m.ContactModule) },
  { path: 'about', loadChildren: () => import('./about/about.module').then((m) => m.AboutModule) },
  {
    path: 'services',
    children: [
      { path: 'mouthguards', loadChildren: () => import('./mouthguard/mouthguard.module').then((m) => m.MouthguardModule) },
      { path: 'mobile', loadChildren: () => import('./mobile-services/mobile-services.module').then((m) => m.MobileServicesModule) },
      { path: 'dentures', loadChildren: () => import('./dentures/dentures.module').then((m) => m.DenturesModule) },
      { path: 'dental-hygiene', loadChildren: () => import('./dental-hygiene/dental-hygiene.module').then((m) => m.DentalHygieneModule) },
      { path: 'laser-therapy', loadChildren: () => import('./laser-therapy/laser-therapy.module').then((m) => m.LaserTherapyModule) },
      { path: '**', redirectTo: '/not-found' }
    ]
  },
  {
    path: 'clinics/:clinicType',
    loadChildren: () => import('./clinic/clinic-list/clinic-list.module').then((m) => m.ClinicListModule)
  },
  { path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule) },
  { path: '**', redirectTo: '/not-found' }
];
