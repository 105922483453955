import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'sty-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent {
  @Input() for?: string;
  @Input() label?: string;
  @Input() control: FormControl;
  @Input() errorMessages?: { [key: string]: string } = {};
}
