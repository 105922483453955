<sty-navbar></sty-navbar>
<section class="plApp-content">
  <router-outlet></router-outlet>

  <section class="styApp-footer">
    <sty-footer></sty-footer>
  </section>

  <section class="styApp-map">
    <sty-map [mapSettings]="mapSettings" [mapAnnotations]="[mapAnnotation]">
    </sty-map>
  </section>
</section>
