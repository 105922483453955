export interface IServiceGroup {
  name: string;
  services: IService[];
}

export interface IService {
  name: string;
  detail?: string;
}

export const HomepageServices: IServiceGroup[] = [
  {
    name: 'Dentures',
    services: [
      { name: 'Complete Denture Care from implant supported to repairs and relines' }
    ]
  },
  {
    name: 'Dental Hygiene',
    services: [
      { name: 'Teeth cleaning', detail: 'scaling and polishing' },
      { name: 'Whitening', detail: 'in chair or take home options' }
    ]
  },
  {
    name: 'Mouthguards',
    services: [
      { name: 'Custom fitted mouthguards' },
      { name: 'Over 60 colours available' }
    ]
  }
];
