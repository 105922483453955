import { ModalRef } from './modal-ref';
import { ActiveModal } from './active-modal';
import {
  ApplicationRef,
  ComponentFactory,
  ComponentFactoryResolver,
  Injectable,
  Injector,
  ReflectiveInjector,
  Type
} from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { ModalWindowComponent } from './modal-window.component';
import { ContentRef } from './content-ref';

@Injectable()
export class ModalStack {
  private _windowFactory: ComponentFactory<ModalWindowComponent>;

  constructor(
    private _appRef: ApplicationRef,
    private _injector: Injector,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _router: Router
  ) {
    this._windowFactory = _componentFactoryResolver.resolveComponentFactory(ModalWindowComponent);
  }

  open(
    componentFactoryResolver: ComponentFactoryResolver,
    contentInjector: Injector,
    content: Type<any>,
    data: any
  ): ModalRef {
    const activeModal = new ActiveModal();

    const contentComponentFactory = componentFactoryResolver.resolveComponentFactory(content);
    const modalContentInjector = Injector.create(
      [{ provide: ActiveModal, useValue: activeModal }],
      contentInjector
    );

    const componentRef = contentComponentFactory.create(modalContentInjector);
    this._appRef.attachView(componentRef.hostView);

    const contentRef = new ContentRef([[componentRef.location.nativeElement]], componentRef.hostView, componentRef);
    Object.keys(data).map((k) => componentRef.instance[k] = data[k]);

    const className = componentRef.location.nativeElement.tagName.toLowerCase().split('-')
      .map((word: string, wordIndex: number) =>
        wordIndex === 0 && word || word.split('').map((letter: string, letterIndex: number) =>
          letterIndex === 0 && letter.toUpperCase() || letter).join('')).join('');

    const windowComponentReference = this._windowFactory.create(this._injector, contentRef.nodes);
    windowComponentReference.instance.className = className;
    this._appRef.attachView(windowComponentReference.hostView);
    document.body.appendChild(windowComponentReference.location.nativeElement);

    const modalRef = new ModalRef(windowComponentReference, contentRef);
    const close$ = new Subject<void>();

    this._router.events
      .pipe(
        takeUntil(close$),
        filter((evt: Event) => evt instanceof NavigationEnd)
      )
      .subscribe(() => activeModal.dismiss());

    activeModal.close = () => {
      modalRef.close();
      close$.complete();
    };

    activeModal.dismiss = () => {
      modalRef.close();
      close$.complete();
    };

    return modalRef;
  }
}
