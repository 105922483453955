import { Directive, Input, HostBinding, OnInit, OnChanges } from "@angular/core";

@Directive({
  selector: '[styStatic]'
})
export class StaticImageDirective implements OnInit, OnChanges {
  @Input('styStatic') path: string;

  @HostBinding('src') src: string = '';

  ngOnInit(): void {
    this._setSrc();
  }

  ngOnChanges(): void {
    this._setSrc();
  }

  private _setSrc(): void {
    this.src = `https://static.smilestoyou.ca/${this.path}`;
  }
}