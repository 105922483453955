import { Subscription } from 'rxjs';
import { ComponentRef } from '@angular/core';
import { filter } from 'rxjs/operators';
import { AnimationState } from '../animations/animation-state';
import { IModalShowable } from './modal-showable';
import { ModalWindowComponent } from './modal-window.component';
import { ContentRef } from './content-ref';

export class ModalRef {
  private _windowDismissSub: Subscription;
  private _windowInstanceSub: Subscription;

  constructor(
    private _windowComponentRef: ComponentRef<ModalWindowComponent>,
    private _contentRef: ContentRef
  ) {
    this._windowDismissSub = this._windowInstance.modalDismiss.subscribe(() => this.dismiss());
    this._windowInstanceSub = this._windowInstance.animationEnd
      .pipe(filter((state) => state === AnimationState.Void))
      .subscribe(() => this._onDestroy());

    if (this.componentInstance && this.componentInstance.closed) {
      this.componentInstance.closed.subscribe(() => this._onDestroy());
    }
  }

  close(): void {
    if (!this._windowComponentRef) {
      return;
    }

    this._destroy();
  }

  dismiss(): void {
    if (!this._windowComponentRef) {
      return;
    }

    this._destroy();
  }

  get componentInstance(): IModalShowable {
    return this._contentRef.componentRef && this._contentRef.componentRef.instance;
  }

  private _destroy(): void {
    if (this.componentInstance && this.componentInstance.closed) {
      this._contentRef.componentRef.destroy();
    } else {
      this._windowInstance.exit();
    }
  }

  private _onDestroy(): void {
    this._windowComponentRef.destroy();

    this._windowInstanceSub.unsubscribe();
    this._windowDismissSub.unsubscribe();

    this._contentRef.componentRef.destroy();

    this._windowComponentRef = this._contentRef =
      this._windowInstanceSub = this._windowDismissSub = null;
  }

  private get _windowInstance(): ModalWindowComponent {
    return this._windowComponentRef.instance;
  }
}
