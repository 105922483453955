<section class="styFooter-content">
  <section class="styFooter-contact">
    <section class="styFooter-address">
      <header class="styFooter-addressHeader">Address</header>

      <section class="styFooter-addressDetails">
        <section class="styFooter-addressStreet">{{ location.address }}</section>
        <section class="styFooter-addressCity">{{ location.city }}, {{ location.province }}</section>
        <section class="styFooter-addressPostalCode">{{ location.postalCode }}</section>
      </section>
    </section>

    <section class="styFooter-phone">
      <header class="styFooter-phoneHeader">Call Us</header>

      <section class="styFooter-phoneDetails">
        <a href="tel:{{ location.phone }}">{{ location.phone }}</a>
      </section>
    </section>
  </section>

  <section class="styFooter-hours">
    <header class="styFooter-hoursHeader">Hours</header>

    <table class="styFooter-hoursList">
      <tr *ngFor="let hour of hours">
        <td class="styFooter-hourDay">{{ hour.day }}</td>
        <td class="styFooter-hourDetail">
          {{ hour.from }} <ng-container *ngIf="hour.to">- {{ hour.to }}</ng-container>
        </td>
      </tr>
    </table>
  </section>

  <section class="styFooter-connect">
    <header class="styFooter-connectHeader">Connect</header>

    <section class="styFooter-connectDetails">
      <a *ngFor="let socialLink of footerSocialLinks" class="styFooter-connectSocialLink" target="_blank" rel="noreferrer" [href]="socialLink.link">
        <fa-icon [icon]="socialLink.icon"></fa-icon>
      </a>
    </section>
  </section>
</section>
