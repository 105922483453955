import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { MetaState } from './meta/meta.state';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { ApiModule } from './api/api.module';
import { ModalModule } from './modal/modal.module';
import { ContactFormModalModule } from './contact/contact-form-modal/contact-form-modal.module';
import { ContactFormModule } from './contact/contact-form/contact-form.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppleMapsModule } from 'ngx-apple-maps';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ImageModule } from './image/image.module';
import { MapModule } from './map/map.module';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    NavbarComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'smiles-to-you' }),
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, { enableTracing: environment.routeTrace, scrollPositionRestoration: 'enabled', initialNavigation: 'enabled' }),
    NgxsModule.forRoot([MetaState], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    ApiModule,
    ModalModule.forRoot(),
    ContactFormModule,
    ContactFormModalModule,
    FontAwesomeModule,
    AppleMapsModule,
    ImageModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MapModule,
    AppleMapsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
