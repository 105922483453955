import { Component, OnInit, HostBinding } from '@angular/core';
import { Store } from '@ngxs/store';
import { UpdateMetaAction } from '../meta/meta.actions';
import { IServiceGroup, HomepageServices } from './homepage-services';
import { OpenGraphType } from '../meta/meta';


@Component({
  
  selector: 'sty-homepage',
  styleUrls: ['homepage.component.scss'],
  templateUrl: 'homepage.component.html'
})
export class HomepageComponent implements OnInit {
  services: IServiceGroup[] = HomepageServices;

  

  constructor(private _store: Store) {}

  ngOnInit(): void {
    this._store.dispatch(new UpdateMetaAction({
      title: 'Smiles to You | Professional And Courteous Dental Care',
      description: 'Professional and courteous dental care',
      openGraph: {
        url: 'https://smilestoyou.ca',
        type: OpenGraphType.BusinessBusiness,
        title: 'Smiles to You',
        description: 'Professional and courteous dental care',
        image: 'https://static.smilestoyou.ca/logos/blue-on-white/full.png'
      }
    }));
  }
}
