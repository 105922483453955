import { ChangeDetectionStrategy, Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, debounceTime } from 'rxjs/operators';
import { MessageContext } from '../message/message';
import { ModalService } from '../modal/modal.service';
import { ContactFormModalComponent } from '../contact/contact-form-modal/contact-form-modal.component';
import { isPlatformBrowser } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sty-navbar',
  styleUrls: ['./navbar.component.scss'],
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {
  active$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private _modalService: ModalService,
    private _router: Router,
    @Inject(PLATFORM_ID) private _platformID: string
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this._platformID)) {
      this.active$
        .pipe(filter((active) => active))
        .subscribe(() => document.body.classList.add('navbarOpen'));

      this.active$
        .pipe(filter((active) => !active))
        .subscribe(() => document.body.classList.remove('navbarOpen'));

      this._router.events
        .pipe(filter((evt) => evt instanceof NavigationEnd), debounceTime(100))
        .subscribe(() => this.closeMenu());
    }
  }

  toggleMenu(): void {
    this.active$.next(!this.active$.value);
  }

  openMenu(): void {
    this.active$.next(true);
  }

  closeMenu(): void {
    this.active$.next(false);
  }

  openRequestModal(): void {
    this._modalService.open(ContactFormModalComponent, {context: MessageContext.Navbar});
  }
}
