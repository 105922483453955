import { Component, OnInit, OnDestroy, HostBinding, Input, Output, EventEmitter, ElementRef, Renderer2, HostListener } from '@angular/core';
import { AnimationState } from '../animations/animation-state';
import { AnimationEvent, transition, animate, style, trigger } from '@angular/animations';

@Component({
  animations: [
    trigger('easeInOut', [
      transition(`${AnimationState.Void} => ${AnimationState.Enter}`, [
        style({
          opacity: 0
        }),
        animate('300ms ease', style({
          opacity: 1
        }))
      ]),
      transition(`${AnimationState.Enter} => ${AnimationState.Void}`, [
        style({
          opacity: 1
        }),
        animate('300ms ease', style({
          opacity: 0
        }))
      ])
    ])
  ],
  selector: 'sty-modal-window',
  styleUrls: ['./modal-window.component.scss'],
  template: `<ng-content></ng-content>`
})
export class ModalWindowComponent implements OnInit, OnDestroy {
  @HostBinding('class') get elementClass(): string {
    return 'modalWindow';
  }

  @HostBinding('@easeInOut') animationState: string;

  @Input() className: string;

  @Output() modalDismiss: EventEmitter<void> = new EventEmitter<void>();
  @Output() animationEnd: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _el: ElementRef, private _renderer: Renderer2) {
    this.animationState = AnimationState.Enter;
  }

  @HostListener('click', ['$event.target'])
  onClick(target: HTMLElement): void {
    if (this._el.nativeElement !== target) {
      return;
    }

    this.modalDismiss.emit();
  }

  @HostListener('window:keyup.esc', ['$event.target'])
  onEsc(target: HTMLElement): void {
    this.modalDismiss.emit();
  }

  @HostListener('@easeInOut.done', ['$event'])
  onAnimationEnd(animationEvent: AnimationEvent): void {
    this.animationEnd.emit(animationEvent.toState);
  }

  ngOnInit(): void {
    this._renderer.addClass(document.body, 'modalOpen');
  }

  ngOnDestroy(): void {
    this._renderer.removeClass(document.body, 'modalOpen');
  }

  exit(): void {
    this.animationState = AnimationState.Void;
  }
}
