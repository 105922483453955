import { NgModule } from "@angular/core";
import { ContactFormComponent } from "./contact-form.component";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MessageModule } from "src/app/message/message.module";
import { NgxMaskModule } from "ngx-mask";
import { FormInputComponent } from "./form-input/form-input.component";
import { InputErrorsComponent } from "./input-errors/input-errors.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
  declarations: [
    ContactFormComponent,
    FormInputComponent,
    InputErrorsComponent
  ],
  exports: [
    ContactFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MessageModule,
    NgxMaskModule.forRoot(),
    FontAwesomeModule
  ]
})
export class ContactFormModule {}