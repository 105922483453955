import { Title, Meta } from '@angular/platform-browser';
import { Action, State, StateContext } from '@ngxs/store';
import { cloneDeep } from 'lodash';

import { UpdateMetaAction } from './meta.actions';
import { IMeta, IOpenGraph, OpenGraphType } from './meta';
import { Injectable } from '@angular/core';

const defaultOpenGraph: IOpenGraph = {
  url: 'https://smilestoyou.ca',
  type: OpenGraphType.BusinessBusiness,
  title: 'Smiles to You',
  description: 'Professional, affordable, and compassionate dental care.',
  image: '/'
};

const defaultState: IMeta = {
  title: 'Smiles to You',
  description: 'Professional, affordable, and compassionate dental care.',
  openGraph: defaultOpenGraph
};
​
@State<IMeta>({
  name: 'meta',
  defaults: defaultState
})
@Injectable()
export class MetaState {
  constructor(
    private _title: Title,
    private _meta: Meta
  ) {}

  @Action(UpdateMetaAction)
  updateMeta(ctx: StateContext<IMeta>, action: UpdateMetaAction): void {
    const oldState: IMeta = cloneDeep(ctx.getState().openGraph);
    ctx.setState(action.meta);
    const state = ctx.getState();

    this._updateTitleDescription(state);

    if (oldState) {
      this._updateOpenGraph(oldState.openGraph, state.openGraph);
    }
  }

  private _updateTitleDescription(state: IMeta): void {
    this._title.setTitle(state.title || defaultState.title);
    this._meta.updateTag({name: 'description', content: state.description || defaultState.description});
  }

  private _updateOpenGraph(oldOpenGraph: IOpenGraph, newOpenGraph: IOpenGraph = defaultOpenGraph): void {
    Object.keys(oldOpenGraph || {}).forEach((k) => this._meta.removeTag(`og:${k}`));

    const updateOpenGraph = {
      ...defaultOpenGraph,
      ...newOpenGraph
    };
    Object.keys(updateOpenGraph).forEach((k) => this._meta.addTag({ property: `og:${k}`, content: newOpenGraph[k] }));
  }
}
