
import { ModuleWithProviders, NgModule } from '@angular/core';

import { ModalService } from './modal.service';

import { ActiveModal } from './active-modal';
import { ModalStack } from './modal-stack';
import { ModalWindowComponent } from './modal-window.component';

@NgModule({
  declarations: [
    ModalWindowComponent
  ],
  entryComponents: [
    ModalWindowComponent
  ],
  providers: [
    ModalService,
    ModalStack,
    ActiveModal
  ]
})
export class ModalModule {
  static forRoot(): ModuleWithProviders<ModalModule> {
    return {
      ngModule: ModalModule, providers: [ModalService, ModalStack]
    };
  }
}
