import { Component, Input, AfterViewInit, ElementRef, OnInit, Inject, PLATFORM_ID, EventEmitter, Output } from "@angular/core";
import { MapKitInitOptions, MapConstructorOptions } from "ngx-apple-maps/lib/declarations";
import { Observable, Subject, BehaviorSubject, combineLatest } from "rxjs";
import { MapService } from "./map.service";
import { ReactiveComponent } from "../reactive/reactive.component";
import { filter, map, take } from "rxjs/operators";
import { IMapAnnotation } from "./map";
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: 'sty-map',
  template: `
    <ngx-apple-maps *ngIf="mapkitReady$ | async" [options]="mapOptions$ | async" [settings]="mapSettings" (onLoaded)="mapLoaded($event)">
      <ngx-apple-maps-annotation *ngFor="let annotation of mapAnnotations" [options]="annotation.annotationOptions" [latitude]="annotation.latitude" [longitude]="annotation.longitude"></ngx-apple-maps-annotation>
    </ngx-apple-maps>
  `
})
export class MapComponent extends ReactiveComponent implements AfterViewInit, OnInit {
  @Input() mapSettings: MapConstructorOptions = {};
  @Input() mapAnnotations: IMapAnnotation[];

  @Output() mapLoad: EventEmitter<any> = new EventEmitter<any>(true)

  componentInView$: BehaviorSubject<boolean> = this.registerBehaviourSubject<boolean>(false);
  mapkitReady$: Observable<boolean>;
  mapOptions$: Observable<MapKitInitOptions>;

  subject$: Observable<any>;

  constructor(private _mapService: MapService, private _elementRef: ElementRef<HTMLElement>, @Inject(PLATFORM_ID) private _platformId) {
    super();
  }

  ngOnInit(): void {
    this.mapOptions$ = this._mapService.token$.pipe(
      map((token) => ({ JWT: token }))
    );

    this.componentInView$.pipe(filter(Boolean), take(1)).subscribe(() => this._mapService.initMaps());
    
    this.mapkitReady$ = combineLatest(this.componentInView$, this._mapService.mapServiceInit$, this.mapOptions$).pipe(
      map(([inView, serviceReady, {JWT}]) => inView && serviceReady && !!JWT)
    )
  }

  ngAfterViewInit(): void {
    this._initIntersectionObserver();
  }

  mapLoaded(map: any): void {
    this.mapLoad.emit(map);
  }

  private _initIntersectionObserver(): void {
    if (!isPlatformBrowser(this._platformId)) {
      return;
    }

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        this.componentInView$.next(true);
        observer.disconnect();
      }
    });
    
    observer.observe(this._elementRef.nativeElement);
  }
}
