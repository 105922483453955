import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ContactFormModule } from "../contact-form/contact-form.module";
import { ContactFormModalComponent } from "./contact-form-modal.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
  declarations: [
    ContactFormModalComponent
  ],
  imports: [
    CommonModule,
    ContactFormModule,
    FontAwesomeModule
  ]
})
export class ContactFormModalModule {}