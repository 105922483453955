import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiService {
  constructor(private _http: HttpClient) {}

  get<T>(path, params: any = {}): Observable<T> {
    return this._http
      .get(this._buildPath(path), {params: this._searchParams(params)})
      .pipe(
        map((val) => val as T),
        catchError((val) => this._buildErrors(val) as Observable<any>)
      );
  }

  post<T>(path, model: T, key: string, params: any = {}): Observable<T> {
    return this._http
      .post(this._buildPath(path), {[key]: model, params: this._searchParams(params)})
      .pipe(
        map((val) => val as T),
        catchError((val) => this._buildErrors(val) as Observable<any>)
      );
  }

  private _searchParams(params: any = {}): HttpParams {
    let searchParams = new HttpParams();

    Object.keys(params).forEach((key) => {
      if (params[key] instanceof Array) {
        params[key].forEach((value, i) => searchParams = searchParams.append(key, value));
      } else {
        searchParams = searchParams.set(key, params[key]);
      }
    });

    return searchParams;
  }

  private _buildPath(path = ''): string {
    return `${environment.apiBase}/${path}`;
  }

  private _buildErrors(response: any): Observable<string[]> {
    return throwError(response.error.errors);
  }
}
