import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { FooterSocialLinks, IFooterSocialLink } from './footer-social-links';

interface IHour {
  day: string;
  from: string;
  to: string;
}

@Component({
  selector: 'sty-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  location: any = environment.officeLocation;
  footerSocialLinks: IFooterSocialLink[] = FooterSocialLinks;

  hours: IHour[] = [
    { day: 'Monday', from: '8 am', to: '4 pm' },
    { day: 'Tuesday', from: '10 am', to: '6 pm' },
    { day: 'Wednesday', from: '8 am', to: '4 pm' },
    { day: 'Thursday', from: '8 am', to: '8 pm' },
    { day: 'Friday', from: '8 am', to: '2 pm' },
    { day: 'Saturday', from: 'by appointment only', to: null }
  ];
}
