import { NgModule } from "@angular/core";
import { MapComponent } from "./map.component";
import { CommonModule } from "@angular/common";
import { AppleMapsModule } from "ngx-apple-maps";

@NgModule({
  declarations: [
    MapComponent
  ],
  exports: [
    MapComponent
  ],
  imports: [
    CommonModule,
    AppleMapsModule
  ]
})
export class MapModule {}