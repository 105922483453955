<a [routerLink]="'/'" class="styNavbar-rootLink">
  <img alt="Smiles to You Logo" loading="lazy" class="styNavbar-rootLinkImg" styStatic="logos/blue-on-white/text-only.png">
  <img alt="Smiles to You Logo" loading="lazy" class="styNavbar-rootLinkImgMobile" styStatic="/logos/white-on-transparent/text-only.png">
</a>

<button class="styNavbar-mobileToggle" [class.isActive]="active$ | async" (click)="toggleMenu()">
  <div class="styNavbar-mobileToggleBar"></div>
  <div class="styNavbar-mobileToggleBar"></div>
  <div class="styNavbar-mobileToggleBar"></div>
</button>

<section class="styNavbar-links" [class.isActive]="active$ | async">
  <button class="styNavbar-dropdown">
    Services
    <ul class="styNavbar-dropdownList">
      <li class="styNavbar-dropdownItem">
        <a class="styNavbar-dropdownLink" [routerLink]="['/services','dentures']" routerLinkActive="isActive">
          Dentures
        </a>
      </li>
      <li class="styNavbar-dropdownItem">
        <a class="styNavbar-dropdownLink" [routerLink]="['/services','dental-hygiene']" routerLinkActive="isActive">
          Dental Hygiene
        </a>
      </li>
      <li class="styNavbar-dropdownItem">
        <a class="styNavbar-dropdownLink" [routerLink]="['/services','laser-therapy']" routerLinkActive="isActive">
          Laser Therapy
        </a>
      </li>
      <li class="styNavbar-dropdownItem">
        <a class="styNavbar-dropdownLink" [routerLink]="['/services','mobile']" routerLinkActive="isActive">
          Mobile Services
        </a>
      </li>
      <li class="styNavbar-dropdownItem">
        <a class="styNavbar-dropdownLink" [routerLink]="['/services','mouthguards']" routerLinkActive="isActive">
          Mouthguards
        </a>
      </li>
    </ul>
  </button>
  <ul class="styNavbar-dropdownList isMobile">
    <li class="styNavbar-dropdownItem">
      <a class="styNavbar-dropdownLink" [routerLink]="['/services','dentures']" routerLinkActive="isActive">Dentures</a>
    </li>
    <li class="styNavbar-dropdownItem">
      <a class="styNavbar-dropdownLink" [routerLink]="['/services','dental-hygiene']" routerLinkActive="isActive">Dental Hygiene</a>
    </li>
    <li class="styNavbar-dropdownItem">
      <a class="styNavbar-dropdownLink" [routerLink]="['/services','laser-therapy']" routerLinkActive="isActive">Laser Therapy</a>
    </li>
    <li class="styNavbar-dropdownItem">
      <a class="styNavbar-dropdownLink" [routerLink]="['/services','mobile']" routerLinkActive="isActive">Mobile Services</a>
    </li>
    <li class="styNavbar-dropdownItem">
      <a class="styNavbar-dropdownLink" [routerLink]="['/services','mouthguards']" routerLinkActive="isActive">Mouthguards</a>
    </li>
  </ul>
  <a class="styNavbar-link" [routerLink]="['about']" routerLinkActive="isActive">About Us</a>
  <a class="styNavbar-link" [routerLink]="['contact']" routerLinkActive="isActive">Contact</a>
  <button type="button" class="styNavbar-apptBtn" (click)="openRequestModal()">Request Appointment</button>
</section>
