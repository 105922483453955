import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { map, catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Observable, Subject, BehaviorSubject, from, combineLatest } from "rxjs";

@Injectable({providedIn: 'root'})
export class MapService {
  token: string;
  
  token$: BehaviorSubject<string> = new BehaviorSubject(null);
  
  private mapService$: BehaviorSubject<any> = new BehaviorSubject(null);
  mapServiceInit$: Observable<boolean> = this.mapService$.pipe(map(Boolean));
  
  constructor(@Inject(PLATFORM_ID) private _platformId: string, private _http: HttpClient) {}

  initMaps(): void {
    if (!isPlatformBrowser(this._platformId)) {
      return null;
    }
    
    const token$ = this._http.get<{token: string}>('/mapkit/token')
      .pipe(
        map(({token}) => token),
        catchError(() => ['']),
        tap((token) => this.token = token),
      );

    const mapkit$ = from(import('mapkit.js')).pipe(map((i) => i.default));

    combineLatest(token$, mapkit$).subscribe(([token, mapkit]) => {
      this.token$.next(token);
      this.mapService$.next(mapkit);
    })
  }
}