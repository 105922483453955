import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { IMessage } from './message';

@Injectable({providedIn: 'root'})
export class MessageService {
  constructor(private _apiService: ApiService) {}

  create(message: Partial<IMessage>): Observable<IMessage> {
    return this._apiService
      .post<IMessage>('messages', message as IMessage, 'message');
  }
}
