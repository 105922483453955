import { NgModule } from "@angular/core";
import { StaticImageDirective } from "./image.directive";

@NgModule({
  declarations: [
    StaticImageDirective
  ],
  exports: [
    StaticImageDirective
  ]
})
export class ImageModule {}