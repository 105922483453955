export const environment = {
  production: true,
  routeTrace: false,
  mapboxToken: 'pk.eyJ1Ijoiam9zaHRhdGUwNCIsImEiOiJjamo5OW1pa2YyazlwM3FvaGN4d2lsaWZoIn0.XuM5XiYfTTsMP43NMk45Dw',
  officeLocation: {
    lng: -78.3172439,
    lat: 44.305835,
    address: '85 Hunter St W',
    city: 'Peterborough',
    province: 'ON',
    postalCode: 'K9H 2K5',
    phone: '(705) 775-5575'
  },
  apiBase: 'https://api-staging.smilestoyou.ca/api',
  reCAPTCHA: '6LfSimQUAAAAAPIxea74GJwB7AayLTrZX5FGVK9b'
};
