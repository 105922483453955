export enum MessageContext {
  Homepage = 'homepage',
  Navbar = 'navbar_modal',
  Clinic = 'clinic',
  ContactPage = 'contact_page',
  MobileServicesPage = 'mobile_services_page',
  Dentures = 'dentures',
  DentalHygiene = 'dental_hygiene',
  LaserTherapy = 'laser_therapy'
}

export enum MailchimpTypes {
  Generic = 'generic',
  Mouthguard = 'mouthguard'
}

export enum MobileServiceLocation {
  Retirement = 'Retirement Residence',
  Longterm = 'Long Term Care Residence',
  Home = 'Private Home',
  Business = 'Private Business'
}

export interface IMessage {
  name: string;
  email: string;
  phone: string;
  message: string;
  context: MessageContext;
  mailchimp_lists?: MailchimpTypes[];
  clinic_slug?: string;
  mobile_services_location?: MobileServiceLocation;
}

export const SubscribeMessages = {
  [MailchimpTypes.Generic]: 'I would like to hear more about Smiles to You',
  [MailchimpTypes.Mouthguard]: 'I would like to hear more about upcoming Mouthguard Clinics'
};
